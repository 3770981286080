<template>
  <div class="login-oro-container">
    <v-container class="d-flex flex-column justify-content align-center">
      <CategoryTitle :category="category" />

      <Login @loggedIn="loggedIn" />
    </v-container>
    <v-dialog v-model="showDialog" content-class="dialog-confirm">
      <v-card class="pa-5">
        <v-card-text class="text-body-1">
          {{ $t("loginOro.confirm") }}
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            depressed
            :to="{
              name: 'Home'
            }"
            @click="showDialog = false"
          >
            {{ $t("common.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
.login-oro-container {
  background-image: url(/img_layout/tco/tco-login-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .dialog-confirm {
    max-width: 500px;
  }
}
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Login from "@/components/Login.vue";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

import { mapGetters } from "vuex";

export default {
  name: "LoginOro",
  mixins: [reload, categoryMixin],
  components: { CategoryTitle, Login },
  data() {
    return {
      showDialog: false
    };
  },
  computed: {
    ...mapGetters({
      getLegalById: "cart/getLegalById"
    }),
    isOroUser() {
      return this.getLegalById(12);
    }
  },
  methods: {
    async loggedIn() {
      if (this.isOroUser) {
        this.$router.push({
          name: "TcoPerks"
        });
      } else {
        this.showDialog = true;
      }
    }
  },
  mounted() {}
};
</script>
